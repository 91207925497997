<template>
  <div class="absolute top-0 w-screen h-screen flex items-center justify-center z-10">
    <div class="w-full lg:w-1/3 px-4 lg:px-0 h-1/2 overflow-y-auto">
      <p class="w-full text-sm lg:text-xl font-semibold leading-loose text-white tracking-wide text-justify">
        Producer; Mixer and Audio Engineer Aimed to create music using state of the art tools, to make a lifetime dream
        come true,
        I rebuilt back the MAudioLabs Studio, where all the magic takes place. Sided with talented people, songwriters
        lyrics and singers, I create the musical tunes I have never dreamed off to be possible. I compose, mix , produce
        and master the tracks providing them worldwide over the Internet. So currently my tracks are on YouTube, Spotify,
        GooglePlay, Itunes, AmazonMusic, etc... Thank you all for the support.
      </p>
      <p class="mt-8 w-full text-right text-lg font-bold text-white tracking-wider">MDMelo</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
}
</script>
